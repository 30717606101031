import { Dispatch, FunctionComponent, useState, useEffect } from "react";

import { UserIcon, CollectionIcon } from "@heroicons/react/outline";

import { ModelsList } from "components/elements/ModelsList";
import { ExpertOverview } from "components/elements/ExpertOverview";
import { BackButton } from "components/elements/NavigationButtons";
import UserAvatar from "@/components/elements/UserAvatar";

import { UserProfileData } from "@/lib/interfaces/User";
import { GuestExpertWithWrappers } from "@/lib/interfaces/GuestExpert";
import { GuestModelWrapper } from "@/lib/interfaces/ModelWrapper";

//#region INTERFACES
interface ExpertProfileProps {
  selectedExpert?: GuestExpertWithWrappers;
  focusedModel: GuestModelWrapper;
  selectedRegion?: string;
  sectorExperts: GuestExpertWithWrappers[];
  onModelSelect: Dispatch<GuestModelWrapper>;
  onExpertSelect: Dispatch<GuestExpertWithWrappers>;
  onBackClicked: Dispatch<void>;
  onCloseClicked: Dispatch<void>;
}
//#endregion

//#region NESTED TYPES
enum ProfileTabType {
  ExpertOverview,
  ExpertModels,
}
//#endregion

//#region PUBLIC API
export const ExpertProfile: FunctionComponent<ExpertProfileProps> = ({
  selectedExpert,
  focusedModel,
  selectedRegion,
  sectorExperts,
  onModelSelect,
  onExpertSelect,
  onBackClicked,
  onCloseClicked,
}) => {
  const [currentTab, setCurrentTab] = useState<ProfileTabType>(
    ProfileTabType.ExpertOverview
  );
  const [userProfile, setUserProfile] = useState<UserProfileData | undefined>(selectedExpert?.user.profile);

  useEffect(() => {
    if (!userProfile) {
      setUserProfile(selectedExpert?.user.profile);
    }
  }, []);

  return (
    <>
      <div className="border-2 rounded-2xl mx-6 mb-2">
        <div className="grid grid-cols-1 divide-y divide-gray-400 space-y-2 text-dark-background">
          <div className="ml-8 mt-4">
            <BackButton
              labelText={selectedRegion}
              includeRoot={true}
              rootText={"Global"}
              onBackClicked={() => {
                onBackClicked();
              }}
              onRootClicked={() => {
                onCloseClicked();
              }}
            />
          </div>
          <div className="flex mx-8 space-x-3 justify-items-start overflow-x-auto">
            {sectorExperts.slice(0, 7).map((sectorExpert) => (
              <div
                key={sectorExpert.user._id}
                className={`mt-3 mb-1 hover:cursor-pointer`}
                onClick={() => {
                  if (sectorExpert !== selectedExpert) {
                    onExpertSelect(sectorExpert);
                    setUserProfile(undefined);
                  }
                }}
              >
                <UserAvatar
                  size={"h-14 w-14"}
                  background={"bg-turqoise-blue"}
                />
              </div>
            ))}
          </div>
          {selectedExpert && (
            <div className="flex mx-8 items-center">
              <div className="my-6">
                <UserAvatar
                  size={"h-20 w-20"}
                  background={"bg-turqoise-blue"}
                />
              </div>
              <div className="font-aiea">
                {userProfile?.position !== "None" && (
                  <h2 className="ml-6 mr-0 text-lg font-bold">
                    {userProfile?.position} Expert
                  </h2>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="grid grid-cols-1 divide-y divide-gray-400">
          <div className="px-8 flex space-x-4 shadow-lg">
            <div className="grid grid-cols-1 mb-2 content-center justify-items-center space-y-1">
              <span
                onClick={() => {
                  setCurrentTab(ProfileTabType.ExpertOverview);
                }}
                className={`grid grid-cols-1 h-10 w-10 rounded-full bg-turqoise-blue ${currentTab === ProfileTabType.ExpertOverview
                  ? "text-white bg-opacity-100 hover:bg-opacity-0 hover:text-denim-900 hover:border-denim-900 hover:border-solid hover:border-2"
                  : "text-dark-background border-solid border-2 bg-opacity-0 border-black hover:text-denim-900 hover:border-denim-900"
                  } content-center justify-items-center hover:cursor-pointer transition-all ease-out duration-300`}
              >
                <UserIcon className="h-6 w-6 align-middle" />
              </span>
              <p className="font-aiea text-xs text-center text-dark-background">
                Profile
              </p>
            </div>
            <div className="grid grid-cols-1 mb-2 content-center justify-items-center space-y-1">
              <span
                onClick={() => {
                  setCurrentTab(ProfileTabType.ExpertModels);
                }}
                className={`animate-pulse grid grid-cols-1 h-10 w-10 rounded-full bg-turqoise-blue ${currentTab === ProfileTabType.ExpertModels
                  ? "text-white bg-opacity-100 hover:bg-opacity-0 hover:text-denim-900 hover:border-denim-900 hover:border-solid hover:border-2"
                  : "text-dark-background border-solid border-2 bg-opacity-0 border-black hover:text-denim-900 hover:border-denim-900"
                  } content-center justify-items-center hover:cursor-pointer transition-all ease-out duration-300`}
              >
                <CollectionIcon className="h-6 w-6 align-middle" />
              </span>
              <p className="font-aiea text-xs text-center text-dark-background">
                Digital Twins
              </p>
            </div>
          </div>
          <div>
            {selectedExpert && currentTab === ProfileTabType.ExpertOverview && (
              <div
                className={
                  "px-8 overflow-y-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 scrollbar-track-rounded scrollbar-thumb-rounded"
                }
              >
                <ExpertOverview
                  expert={selectedExpert.user}
                  onViewModelClicked={() => onModelSelect(focusedModel)}
                />
              </div>
            )}

            {selectedExpert && currentTab === ProfileTabType.ExpertModels && (
              <div className="">
                <ModelsList
                  modelWrappers={selectedExpert.wrappers}
                  experts={sectorExperts.map((_) => _.user)}
                  isExploring={false}
                  onModelSelect={onModelSelect}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
//#endregion
